import React, { ReactElement } from "react"
import { Header } from "../components/Header"
import { Footer } from '../components/Footer';

interface Props { }

function Features(_props: Props): ReactElement {
  return (
    <>
      <Header />


      <Footer />
    </>
  )
}

export default Features
